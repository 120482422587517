var __initWidget;

window.onload = __initWidget = function(ev, previewUrl, params) {
    window.addEventListener("message", listenMessage, false);
    var isBranded = getValueFromAttribute('script', 'branded') || true;
    var isExpanded = getValueFromAttribute('script', 'data-widget-expanded') === 'true';

    function calcPosition(node, position, { offsetX, offsetY } = {}) {
        switch (position) {
            case "top-left":
                node.style.top = offsetY;
                node.style.left = offsetX;
                break;
            case "top-right":
                node.style.top = offsetY;
                node.style.right = offsetX;
                break;
            case "bottom-left":
                node.style.bottom = offsetY;
                node.style.left = offsetX;
                break;
            case "bottom-right":
                node.style.bottom = offsetY;
                node.style.right = offsetX;
                break;
            case "center":
                node.style.top = "50%";
                node.style.left = "50%";
                node.style.transform = "translate(-50%, -50%)";
                break;
            default:
                node.style.bottom = offsetY;
                node.style.right = offsetX;
        }
    }

    function listenMessage(event) {
        var iframeNode = document.getElementById("remonline-widget-frame");

        if (event.data === "pass integration") {
            iframeNode.contentWindow.postMessage(
                { integration: params },
                '*'
            );
        }

        if (event.data.hasOwnProperty('height')) {
            iframeNode.style.height = event.data.height;
        }

        if (event.data.hasOwnProperty('width')) {
            iframeNode.style.width = event.data.width;
        }

        if (event.data.hasOwnProperty('position')) {
            var offsetX = event.data.offsetX + 'px';
            var offsetY = event.data.offsetY + 'px';

            calcPosition(
                iframeNode,
                event.data.position,
                { offsetX, offsetY }
            );
        }

        if (event.data.hasOwnProperty('hide')) {
            iframeNode.style.display = event.data.hide ? 'none' : 'block';
        }
    }

    function generateFrame(iframeUrl = "", params = {}) {
        var IFrame = document.createElement('iframe');
        IFrame.setAttribute('id', 'remonline-widget-frame');
        IFrame.setAttribute("src", iframeUrl);
        IFrame.style.border = 'none';
        IFrame.style.margin = 'none';
        IFrame.style.width = previewUrl && (params.position === 'bottom-right' || params.position === 'top-right') ? '290px' : '170px';
        IFrame.style.height =  '46px';
        IFrame.style.overflow = 'hidden';
        IFrame.style.position = "fixed";
        IFrame.style.zIndex = "2147483647";
        IFrame.scrolling = 'no';

        if (params) {
            calcPosition(
                IFrame,
                params.position,
                { offsetX: params.offsetX, offsetY: params.offsetY }
            );
        }

        return IFrame;
    }

    function getValueFromAttribute(tagName, attr) {
        var scriptNode = document.querySelector(`${tagName}[${attr}]`);

        if (scriptNode) {
            return  scriptNode.getAttribute(attr);
        }

        return undefined;
    }

    function removeSameDomNode(id) {
        var node = document.getElementById(id);
        if (node) { node.remove() }
    }

    function getLocation(href) {
        var link = document.createElement("a");
        link.href = href;

        return link.origin;
    }

    window.__statusWidget = {
        open: function(){
            var iframe = document.getElementById("remonline-widget-frame");
            iframe.style.display = "block";

            iframe.contentWindow.postMessage(
                { open: {
                        isButtonVisible: false,
                        isDialogWindowVisible: true
                    } },
                '*'
            );
        }
    }

    if (previewUrl) {
        removeSameDomNode("remonline-widget-frame");
        document.body.appendChild(generateFrame(previewUrl, params));
    } else {
        var key = getValueFromAttribute('script', 'data-widget-key');
        var previewHost = getLocation(document.querySelector("script[data-widget-key]").src);
        var iframeUrl = `${previewHost}/static/dist/new_widget.html?key=${key}&is_preview=false&is_expanded=${isExpanded}&is_branded=${isBranded}`;

        removeSameDomNode("remonline-widget-frame");
        document.body.appendChild(generateFrame(iframeUrl));
    }
};

/*** EXPORTS FROM exports-loader ***/
module.exports = __initWidget;
